// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-blog-index-js": () => import("./../src/blog/index.js" /* webpackChunkName: "component---src-blog-index-js" */),
  "component---src-blog-category-js": () => import("./../src/blog/category.js" /* webpackChunkName: "component---src-blog-category-js" */),
  "component---src-blog-post-js": () => import("./../src/blog/post.js" /* webpackChunkName: "component---src-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-backup-js": () => import("./../src/pages/index_backup.js" /* webpackChunkName: "component---src-pages-index-backup-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

